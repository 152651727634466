<template>
  <div class="row select-favorite-color-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/fashion-details.png"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">Your Fashion Details.</h2>
          <p class="description-details">
            Knowing your typical sizes for different pieces of clothing will help us provide you with better fashion and shopping support.
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div 
            class="mobile-back-button d-inline-block d-sm-none" 
            @click="$emit('backButtonAction', 'shopping-details')"
          >
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <progressNavbar :currentStep="1" />

        </div> <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">YOUR Fashion Details</h2>
          <p class="description-details">
            Knowing your typical sizes for different pieces of clothing will help us provide you with better fashion and shopping support.
          </p>
        </div>
        <div class="color-selection-box p-0 pb-sm-4">
          <div class="form-data">
              <div class="form-group">
                <label>Shirt</label>
                <RotarySelectionForm 
                  :choices="shirtSizes" 
                  :currChoice="current.shirtSize"
                  @handleClick="(userChoice) => current.shirtSize = userChoice.choice"
                />
              </div>
              <div class="form-group">
                <label>Pants</label>
                <RotarySelectionForm 
                  :choices="pantSizes" 
                  :currChoice="current.pantSize"
                  @handleClick="(userChoice) => current.pantSize = userChoice.choice"
                />
              </div>
              <div class="form-group">
                <label>Dresses</label>
                <RotarySelectionForm 
                  :choices="DressSizes" 
                  :currChoice="current.DressSize"
                  @handleClick="(userChoice) => current.DressSize = userChoice.choice"
                />
              </div>
              <div class="form-group">
                <label>Shoes</label>
                <RotarySelectionForm 
                  :choices="ShoeSizeNums" 
                  :currChoice="current.ShoeSizeNum"
                  @handleClick="(userChoice) => current.ShoeSizeNum = userChoice.choice"
                />
              </div>
              <div class="form-group">
                <RotarySelectionForm 
                  :choices="ShoeSizes" 
                  :currChoice="current.ShoeSize"
                  :flexible="true"
                  @handleClick="(userChoice) => current.ShoeSize = userChoice.choice"
                />
              </div>
            </div>

        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'shopping-details')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'your-body-type')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RotarySelectionForm from "./forms/RotarySelectionForm.vue"
import progressNavbar from "../components/navigation/progressBar.vue"


export default {
  name: "FashionDetails",
  components: {
    RotarySelectionForm,
    progressNavbar
	},
	data: () => {
		return {
      current: {
        shirtSize: "M",
        pantSize: "2",
        DressSize: "4",
        ShoeSizeNum: "5.5",
        ShoeSize: "Medium"
      },
      shirtSizes: ["XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"],
      pantSizes: ["00", "0", "2", "4", "6", "8", "10", "12", "14" ],
      DressSizes: ["00", "0", "2", "4", "6", "8", "10", "12", "14" ],
      ShoeSizeNums: ["4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8" ],
      ShoeSizes: ["Narrow", "Medium", "Wide", "Extra Wide"],
		};
	},
  computed: {
  },
	methods: {
	},
};
</script>

<style lang="scss">
@import "../assets/scss/common.scss";

.form-data .form-group {
    label {
      font-family: $font-family_1;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $color_8;
      margin-bottom: 8px;
      width: 100%;

      span {
        float: right;
      }
    }

    margin-bottom: 16px;
  }

  .form-control:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .form-data {
    min-height: 458px;
    overflow: auto;
  }
</style>
