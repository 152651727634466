<template>
    <svg width="14" height="14" viewBox="0 0 14 14" :fill="`#${color}`" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.25 0H1.75C1.51794 0 1.29538 0.0921872 1.13128 0.256282C0.967187 0.420376 0.875 0.642936 0.875 0.875V13.125C0.875 13.3571 0.967187 13.5796 1.13128 13.7437C1.29538 13.9078 1.51794 14 1.75 14H12.25C12.4821 14 12.7046 13.9078 12.8687 13.7437C13.0328 13.5796 13.125 13.3571 13.125 13.125V0.875C13.125 0.642936 13.0328 0.420376 12.8687 0.256282C12.7046 0.0921872 12.4821 0 12.25 0V0ZM11.0833 2.33333V11.6667C11.0833 11.744 11.0526 11.8182 10.9979 11.8729C10.9432 11.9276 10.869 11.9583 10.7917 11.9583H3.20833C3.13098 11.9583 3.05679 11.9276 3.00209 11.8729C2.9474 11.8182 2.91667 11.744 2.91667 11.6667V2.33333C2.91667 2.25598 2.9474 2.18179 3.00209 2.12709C3.05679 2.0724 3.13098 2.04167 3.20833 2.04167H10.7917C10.869 2.04167 10.9432 2.0724 10.9979 2.12709C11.0526 2.18179 11.0833 2.25598 11.0833 2.33333Z" :fill="`#${color}`"/>
        <path d="M7 7.58325C7.9665 7.58325 8.75 6.79975 8.75 5.83325C8.75 4.86675 7.9665 4.08325 7 4.08325C6.0335 4.08325 5.25 4.86675 5.25 5.83325C5.25 6.79975 6.0335 7.58325 7 7.58325Z" :fill="`#${color}`"/>
        <path d="M7.00058 8.16675C6.4194 8.1669 5.84824 8.31807 5.34307 8.60542C4.8379 8.89277 4.41607 9.30645 4.11891 9.80591C4.09477 9.84651 4.08203 9.89286 4.08203 9.94008C4.08203 9.98731 4.09477 10.0337 4.11891 10.0742C4.14349 10.1156 4.17853 10.1498 4.22051 10.1734C4.2625 10.1969 4.30994 10.209 4.35808 10.2084H9.60807C9.65621 10.209 9.70365 10.1969 9.74564 10.1734C9.78762 10.1498 9.82266 10.1156 9.84724 10.0742C9.87138 10.0337 9.88412 9.98731 9.88412 9.94008C9.88412 9.89286 9.87138 9.84651 9.84724 9.80591C9.55314 9.31151 9.13683 8.90106 8.6383 8.614C8.13978 8.32694 7.57581 8.17293 7.00058 8.16675Z" :fill="`#${color}`"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: String
    }
}
</script>