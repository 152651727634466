<template>
    <div class="row closet-grid select-style-attributes-page">
      <div class="col-sm-12">
        <div class="banner-image">
            <div class="col-lg-5 col-md-6 d-none d-sm-block">
                <div class="welcome-box">
                    <h2 class="welcome-title">{{welcomeTitle}}</h2>
                    <p class="welcome-description">{{welcomeDescription}}</p>
                    <p>
                        <button class="button-white" @click="$emit('continueButtonAction', 'home-slide')">{{ctaButton}}</button>
                    </p>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 d-block welcome-sticky-block d-sm-none">
                <div class="welcome-box">
                    <h2 class="welcome-title">{{welcomeTitle}}</h2>
                    <p class="welcome-description">{{welcomeDescription}}</p>
                </div>
            </div>
            
        </div>
        <div class="d-block d-sm-none">
            <div class="sticky-button">
                <button class="button-white" @click="$emit('continueButtonAction', 'home-slide')">{{ctaButtonMobile}}</button>
            </div>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "WelcomeComponent",
    components: {
      },
      data: () => {
          return {
            welcomeTitle: 'WELCOME TO YOUR CASA BEAU MONDE CLOSET',
            welcomeDescription: 'There were two bad people… One was John Wayne and he’s dead brother, and the other’s right here. Nature Boy Ric Flair, the World’s Heavyweight title belongs to these people. I’mma reach out right now, I want you at home to know my hand is touchin’ your hand for the gathering of the biggest body of people in this country, in this universe, all over the world now, reachin’ out because the love that was given me and this time I will repay you now.',
            ctaButton: 'Get started with a CTA here, Tavy?',
            ctaButtonMobile: 'Get started'
          };
      },
    computed: {
    },
      methods: {
      handleContinueClick() {
        console.log(this.selectedStyles);
      }
      },
  };
  </script>
  
  <style lang="scss">
  @import "../assets/scss/pages/Welcome";
  </style>
  
