<template>
  <div class="px-0">
    <div class="d-none d-lg-flex row">
      <div class="col-md-5 col-12 d-flex flex-column mb-4">
        <span class="subheader">Stylists</span>
        <span class="header">GIVE YOUR TIME AND PASSION THE TOOLS TO WORK HARDER FOR YOU</span>
        <span class="base-text">You put everything into sharing your love of fashion – we’ll help you get more out of your efforts. Boost your visibility, expand your clientele, and highlight the value you bring to the world of fashion.</span>
        <button class="btn btn-light" @click="$emit('continueButtonAction', 'shopping-details')">Create a stylist account</button>
      </div>
      <div class="col-md-7 col-12">
        <img 
          src="../assets/image/side-image/homepage-slide-img.png" 
          alt="Image of lady leaning over a wall" 
          class="img-fit"
        >
      </div>
    </div>
    <div class="d-block d-lg-none px-0">
      <div class="position-relative">
      <img 
          src="../assets/image/side-image/homepage-slide-img.png" 
          alt="Image of lady leaning over a wall" 
          class="img-occupy"
        >
        <div class="sub-container">
          <span class="header-responsive">GIVE YOUR TIME AND PASSION THE TOOLS TO WORK HARDER FOR YOU</span>
          <span class="base-text-responsive">You put everything into sharing your love of fashion – we’ll help you get more out of your efforts.</span>
        </div>
      </div>
      <div class="button-container p-4 d-flex flex-column">
        <button class="btn-login">Login</button>
        <button class="btn-get-started" @click="$emit('continueButtonAction', 'shopping-details')">Get Started</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSlide"
};
</script>

<style lang="scss">
@import "../assets/scss/common.scss";
  .subheader {
    font-family: $font-family_1;
		font-weight: 400;
		font-size: 20px;
		line-height: 32px;
    color: $color_3;
    margin-bottom: 24px;
  }
  .header {
    font-family: $font-family_2;
		font-weight: 400;
		font-size: 48px;
		line-height: 56px;
    color: $color_1;
    margin-bottom: 24px;

    &-responsive {
      font-family: $font-family_2;
      color: $color_1;
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }
  .base-text {
    font-family: $font-family_1;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
    color: #A3A3A3;
    margin-bottom: 24px;
    
    &-responsive {
      font-family: $font-family_1;
      color: $color_1;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .img-fit {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .img-occupy {
    width: 100%;
    min-height: calc(100vh - 52px); 
    object-fit: cover;
  }
  .sub-container {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .btn { 
    &-get-started {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      background: $color_6;
      border: 1px solid $color_6;
      border-radius: 0;
      color: #000000;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    &-login {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      background: none;
      border: 1px solid $color_6;
      border-radius: 0;
      color: $color_1;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
</style>
