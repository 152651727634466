<template>
  <div class="row select-favorite-color-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/shopping-details.png"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">Your Shopping Details.</h2>
          <p class="description-details">
            We’re almost done with the basics.
            We just have a few more questions before we learn about your style.
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none" @click="$emit('backButtonAction', 'home-slide')">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <progressNavbar :currentStep="1"/>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">Fashion & Shopping Details</h2>
          <p class="description-details">
            Select at least four colors - or as many as you want - that suit
            your style. They can all be from one color palette or spread across
            all the palettes.
          </p>
        </div>
      
        <div class="color-selection-box p-0 pb-sm-4">
          <p class="description-details mb-4">Fashion & Shopping Details</p>
          <form @change="listForm()" class="form-data">
            <div class="form-group">
              <label>Is there an elusive fashion item you'd love to have? If so, what is it?</label>
              <input 
                type="text" 
                class="form-control" 
                v-model="ShoppingDetails.items"
              />
            </div>
            <div class="form-group">
              <label>How would you describe your fashion sense?</label>
              <input 
                type="text" 
                class="form-control" 
                v-model="ShoppingDetails.fashionSense"
              />
            </div>
            <div class="form-group">
              <label>Have you ever worked with a stylist or personal shopper before?</label>
              <div class="d-flex align-items-start justify-content-start">
                <div class="d-flex align-items-center justify-content-center w-50">
                  <input 
                    type="radio" 
                    id="previousStylistYes" 
                    name="previousStylist" 
                    class="mr-1"
                    checked
                    v-model="ShoppingDetails.previousStylist"
                    value="yes"
                  />
                  <label for="previousStylistYes" class="mb-0">Yes</label>
                </div>  
                <div class="d-flex align-items-center justify-content-center w-50">
                  <input 
                    type="radio" 
                    id="previousStylistNo" 
                    name="previousStylist" 
                    class="mr-1"
                    v-model="ShoppingDetails.previousStylist"
                    value="no"
                  />
                  <label for="previousStylistNo" class="mb-0">No</label>
                </div>  
              </div>  
            </div>
            <div class="form-group">
              <label>If yes, how was your experience?</label>
              <input 
                type="text" 
                class="form-control"
                v-model="ShoppingDetails.previousExperience"
              />
            </div>
            <div class="form-group">
              <label>What could have made the experience more meaningful or helpful?</label>
              <input 
                type="text" 
                class="form-control" 
                v-model="ShoppingDetails.helpfulFeedback"
              />
            </div>
          </form>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'home-slide')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'fashion-details')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import progressNavbar from "../components/navigation/progressBar.vue"

export default {
  name: "YourShoppingDetails",
  components: {
    progressNavbar
	},
	data: () => {
		return {
      ShoppingDetails: {
        items: "",
        fashionSense: "",
        previousStylist: "yes",
        previousExperience: "",
        helpfulFeedback: ""
      }
		};
	},
  computed: {
  },
	methods: {
    listForm() {
      console.log(this.ShoppingDetails)
    }
	},
};
</script>

<style lang="scss">
@import "../assets/scss/common.scss";

.form-data .form-group {
    label {
      font-family: $font-family_1;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $color_8;
      margin-bottom: 8px;
      width: 100%;

      span {
        float: right;
      }
    }

    margin-bottom: 16px;

    input {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 1px;
      font-family: $font-family_1;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color_1;
      border: none;
      padding: 8px;
    }
    input[type=radio] {
      all: unset;
      border-radius: 50%;
      width: 25px;
      height: 20px;
      background: $color_1;
      transition: 0.2s linear;
    }
    input[type=radio]:checked {
      background: $color_11;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: "";
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: $color_1;
      }
    }
  }

  .form-control:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .form-data {
    min-height: 458px;
    overflow: auto;
  }
</style>
