<template>
   <svg width="10" height="12" viewBox="0 0 10 12" :fill="`#${color}`" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.25047 0.999445H6.75034C6.72938 0.998297 6.70897 0.992275 6.69074 0.981859C6.67251 0.971443 6.65697 0.956919 6.64534 0.939442C6.47651 0.653308 6.23597 0.416157 5.94746 0.251405C5.65896 0.0866539 5.33248 0 5.00025 0C4.66802 0 4.34154 0.0866539 4.05304 0.251405C3.76454 0.416157 3.524 0.653308 3.35517 0.939442C3.34354 0.956919 3.32799 0.971443 3.30976 0.981859C3.29153 0.992275 3.27113 0.998297 3.25016 0.999445H0.750038C0.551115 0.999445 0.36034 1.07847 0.219681 1.21913C0.0790216 1.35979 0 1.55056 0 1.74948V11.25C0 11.4489 0.0790216 11.6397 0.219681 11.7803C0.36034 11.921 0.551115 12 0.750038 12H9.25047C9.44939 12 9.64016 11.921 9.78082 11.7803C9.92148 11.6397 10.0005 11.4489 10.0005 11.25V1.74948C10.0005 1.55056 9.92148 1.35979 9.78082 1.21913C9.64016 1.07847 9.44939 0.999445 9.25047 0.999445ZM8.50043 8.1298C8.50105 8.42861 8.39462 8.71775 8.20041 8.94485L7.24037 10.0649C7.12234 10.2011 6.97645 10.3105 6.81256 10.3855C6.64866 10.4606 6.47058 10.4996 6.29032 10.4999H1.75009C1.68378 10.4999 1.62019 10.4736 1.5733 10.4267C1.52642 10.3798 1.50008 10.3162 1.50008 10.2499V2.74953C1.50008 2.68323 1.52642 2.61963 1.5733 2.57275C1.62019 2.52586 1.68378 2.49952 1.75009 2.49952H8.25042C8.31672 2.49952 8.38032 2.52586 8.4272 2.57275C8.47409 2.61963 8.50043 2.68323 8.50043 2.74953V8.1298Z" :fill="`#${color}`" />
        <path d="M7.00022 4.12451H3.00002C2.90056 4.12451 2.80517 4.16402 2.73484 4.23435C2.66451 4.30468 2.625 4.40007 2.625 4.49953C2.625 4.59899 2.66451 4.69438 2.73484 4.76471C2.80517 4.83504 2.90056 4.87455 3.00002 4.87455H7.00022C7.09968 4.87455 7.19507 4.83504 7.2654 4.76471C7.33573 4.69438 7.37524 4.59899 7.37524 4.49953C7.37524 4.40007 7.33573 4.30468 7.2654 4.23435C7.19507 4.16402 7.09968 4.12451 7.00022 4.12451Z" :fill="`#${color}`"/>
        <path d="M7.00022 5.87476H3.00002C2.90056 5.87476 2.80517 5.91427 2.73484 5.9846C2.66451 6.05493 2.625 6.15031 2.625 6.24977C2.625 6.34924 2.66451 6.44462 2.73484 6.51495C2.80517 6.58528 2.90056 6.62479 3.00002 6.62479H7.00022C7.09968 6.62479 7.19507 6.58528 7.2654 6.51495C7.33573 6.44462 7.37524 6.34924 7.37524 6.24977C7.37524 6.15031 7.33573 6.05493 7.2654 5.9846C7.19507 5.91427 7.09968 5.87476 7.00022 5.87476Z" :fill="`#${color}`" />
        <path d="M5.25013 7.62476H3.00002C2.90056 7.62476 2.80517 7.66427 2.73484 7.7346C2.66451 7.80493 2.625 7.90031 2.625 7.99977C2.625 8.09924 2.66451 8.19462 2.73484 8.26495C2.80517 8.33528 2.90056 8.37479 3.00002 8.37479H5.25013C5.34959 8.37479 5.44498 8.33528 5.51531 8.26495C5.58564 8.19462 5.62515 8.09924 5.62515 7.99977C5.62515 7.90031 5.58564 7.80493 5.51531 7.7346C5.44498 7.66427 5.34959 7.62476 5.25013 7.62476Z" :fill="`#${color}`" />
    </svg>

</template>

<script>
export default {
    props: {
        color: String
    }
}
</script>