<template>
    <svg width="10" height="12" viewBox="0 0 10 12" :fill="`#${color}`" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.73161 3.90909C2.73933 3.96249 2.7638 4.01206 2.8015 4.05066C2.8392 4.08926 2.88818 4.11488 2.94138 4.12385C3.62153 4.2368 4.30961 4.29525 4.99907 4.29865C5.68852 4.29511 6.37659 4.23665 7.05675 4.12385C7.10996 4.11488 7.15894 4.08926 7.19664 4.05066C7.23434 4.01206 7.25881 3.96249 7.26652 3.90909C7.29149 3.73928 7.29149 3.76925 7.83588 0.747645C7.85854 0.622188 7.83237 0.492813 7.76273 0.386028C7.69309 0.279242 7.58525 0.203132 7.4613 0.173291L6.87196 0.0134704C6.75293 -0.0147486 6.62768 0.00170073 6.51997 0.0596983C6.41226 0.117696 6.32958 0.213208 6.28762 0.328117L5.96298 1.32699C5.94538 1.37598 5.91372 1.4187 5.87197 1.44979C5.83022 1.48088 5.78023 1.49897 5.72825 1.5018H4.27987C4.22674 1.5041 4.17435 1.48866 4.13095 1.45792C4.08755 1.42718 4.05561 1.38288 4.04014 1.33199L3.70552 0.323122C3.66272 0.210181 3.58044 0.116577 3.47392 0.0596437C3.3674 0.00271084 3.24386 -0.0136929 3.12617 0.0134704L2.53683 0.173291C2.41289 0.203132 2.30504 0.279242 2.2354 0.386028C2.16576 0.492813 2.13959 0.622188 2.16225 0.747645C2.75159 4.00898 2.71164 3.74927 2.73161 3.90909Z" :fill="color"/>
        <path d="M9.45499 10.2569L7.45723 5.67702C7.41572 5.56469 7.38233 5.44953 7.35734 5.33241C7.34297 5.27048 7.30551 5.21637 7.25261 5.1811C7.19972 5.14584 7.13536 5.13207 7.07266 5.14262C6.39481 5.26039 5.70798 5.31887 5.01997 5.31742C4.33196 5.31887 3.64513 5.26039 2.96728 5.14262C2.90473 5.13327 2.84095 5.14752 2.78833 5.1826C2.73571 5.21768 2.69802 5.27108 2.6826 5.33241C2.65763 5.44228 2.62766 5.55216 2.5927 5.65704L0.545 10.2569C0.485535 10.4329 0.494073 10.6248 0.568934 10.7949C0.643795 10.9649 0.779563 11.1008 0.949546 11.1758C2.23313 11.7117 3.60906 11.9917 4.99999 11.9999C6.39093 11.9917 7.76685 11.7117 9.05044 11.1758C9.22042 11.1008 9.35619 10.9649 9.43105 10.7949C9.50591 10.6248 9.51445 10.4329 9.45499 10.2569Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: String
    }
}
</script>