<template>
  <div class="row select-favorite-color-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/your-body-type.png"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">Your Body Type</h2>
          <p class="description-details">
            Every body is different, but this set of body types covers most people's shapes.
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div 
            class="mobile-back-button d-inline-block d-sm-none" 
            @click="$emit('backButtonAction', 'fashion-details')"
          >
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <progressNavbar :currentStep="2"/>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">Your Body Type</h2>
          <p class="description-details">
            Every body is different, but this set of body types covers most people's shapes.
          </p>
        </div>
        <div class="p-0 pb-sm-4">
          <div class="form-data">
            <p class="description mb-4">Choose the one that best represents you.</p>
            <div class="body-form position-relative">
              <BodyComp 
                v-for="(body, key) in bodyTypes"
                :key="key"
                :body="body"
                class="body-types"
                @selectBodyType="(body) => selectBodyType(body)"
              >  
                <template v-slot:body-type-icon>
                  <component :is="body.img"></component>
                </template>
              </BodyComp>
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'fashion-details')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'select-favourite-colors')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BodyComp from './forms/BodyComp.vue'
import TriangleIcon from './body-image/triangleIcon.vue'
import RoundIcon from './body-image/roundIcon.vue'
import RectangleIcon from './body-image/rectangleIcon.vue'
import HourglassIcon from './body-image/hourglassIcon.vue'
import InvertedTriangleIcon from './body-image/InvertedTriangleIcon.vue'
import progressNavbar from "../components/navigation/progressBar.vue"

export default {
  name: "YourBodyType",
  components: {
    BodyComp,
    TriangleIcon,
    progressNavbar
  },  
  data: () => {
		return {
      current: {
        bodyType: "",
      },
      bodyTypes: [
        {
          label: "Triangle",
          img: TriangleIcon,
          description: "This body type stands out for being a proportionate body between shoulders and hips, with a very marked waist. For this body type, you should highlight the figure using v-necklines. It is important that if you are going to generate volume, it should be both at the top and at the bottom so as not to lose the proportion of your figure."
        },
        {
          label: "Round",
          img: RoundIcon,
          description: "This body type stands out for being a proportionate body between shoulders and hips, with a very marked waist. For this body type, you should highlight the figure using v-necklines. It is important that if you are going to generate volume, it should be both at the top and at the bottom so as not to lose the proportion of your figure."
        },
        {
          label: "Rectangle",
          img: RectangleIcon,
          description: "This body type stands out for being a proportionate body between shoulders and hips, with a very marked waist. For this body type, you should highlight the figure using v-necklines. It is important that if you are going to generate volume, it should be both at the top and at the bottom so as not to lose the proportion of your figure."
        },
        {
          label: "Hourglass",
          img: HourglassIcon,
          description: "This body type stands out for being a proportionate body between shoulders and hips, with a very marked waist. For this body type, you should highlight the figure using v-necklines. It is important that if you are going to generate volume, it should be both at the top and at the bottom so as not to lose the proportion of your figure."
        },
        {
          label: "Inverted Triangle",
          img: InvertedTriangleIcon,
          description: "This body type stands out for being a proportionate body between shoulders and hips, with a very marked waist. For this body type, you should highlight the figure using v-necklines. It is important that if you are going to generate volume, it should be both at the top and at the bottom so as not to lose the proportion of your figure."
        }
      ]
		};
	},
  methods: {
    selectBodyType(body){
      this.current.bodyType = body;
    }
  }
 
};
</script>

<style lang="scss">
@import "../assets/scss/common.scss";

.description {
    color: $color_1;
    font-family: $font-family_1;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
}
  .form-data .form-group {
    label {
      font-family: $font-family_1;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $color_8;
      margin-bottom: 8px;
      width: 100%;

      span {
        float: right;
      }
    }

    margin-bottom: 16px;
  }

  .form-control:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .form-data {
    min-height: 458px;
    overflow: auto;
  }
  .body-types {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .body-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
</style>
