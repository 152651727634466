<template>
    <div class="selection-form" v-dragscroll>
        <div
            v-for="(choice, index) in choices" 
            :key="index"
            class="choices"
            :class="{ 'active': currChoice == choice, 'flexible' : flexible }"
            @click="$emit('handleClick', {'choice': choice})" 
            >
            {{choice}}
        </div>
    </div>
</template>
<script>
import { dragscroll } from 'vue-dragscroll'

export default {
    directives: {
        dragscroll
    },
    components: {
    },
    props: {
        choices: Array,
        currChoice: String,
        flexible: Boolean
    },
    methods: {
        handleClick(e) {
            console.log(e.target);
        }
    }
}
</script>
<style lang="scss">
    @import "../../assets/scss/common.scss";

    .selection-form {
        display: flex;
        overflow-x: scroll;
        cursor: grab;
        scroll-direction: horizontal;

        &::-webkit-scrollbar {
            display: none;
        }

        .choices {
            display: inline-flex;
            color: $color_1;
            border: 1px solid $color_1;
            height: 40px;
            flex-basis: 40px;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: 0.2s linear;
            font-family: $font-family_1;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-right: 5px;
            &.flexible {
                padding: 5px 10px;
                height: auto;
                width: auto;
                flex-basis: auto;
            }
            &.active {
                background: $color_1;
                color: $color_11;
            }
        }
    }
</style>