<template>
    <div>
        <div 
            class="body-comp d-flex flex-row"
            @click="displayModal(body)"
        >
            <div class="body-type body-type-hover d-flex flex-column align-items-center">
                <slot name="body-type-icon"></slot>
                <span class="body-type-label text-center" :id="`#${body.label}ModalLabel`">{{body.label}}</span>
            </div>
        </div>

        <div class="body-type-modal" :class="{'active': showModal}">
            <div >
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="displayModal()">
                    <span class="close-btn" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex align-items-center">
                <div class="d-flex mr-4">
                    <slot name="body-type-icon" width="500px"></slot>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-type-description-label">{{body.label}}</span>
                    <span class="body-type-description-content">{{body.description}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BodyComp",
    props: {
        body: Object,
        isActive: Boolean
    },
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        displayModal(body) {
            this.$emit("selectBodyType", body)
            this.showModal = !this.showModal;
        }
    }
}
</script>
<style lang="scss">
    @import "../../assets/scss/common.scss";
    .body-comp {
        margin-right: 10px;
        transition: 0.2s linear;

        &-is-active {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color_5;
            padding: 15px;
        }
    }

    .body-type {
        margin-bottom: 20px;
        padding: 15px;
        transition: 0.2s linear;

        &-label {
            margin-top: 15px;
            font-family: $font-family-1;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color_8;
        }
        &-hover {
            cursor: pointer;
            &:hover {
                background: $color_6;
            }
        }

        &-description-label {
            display: block;
            font-family: $font-family-1;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: $color_1;
            margin-bottom: 4px;
        }
        &-description-content {
            display: block;
            font-family: $font-family-1;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $color_1;
        }
    }

    .body-type-modal:not(.active) {
        transition: 0.2s linear;
        transform: translateX(1000px);
        display: none;
        position: absolute;
    }

    .body-type-modal.active {
        position: absolute;
        background: $color_5;
        flex-direction: column;
        animation: showModal 0.2s forwards;
    }

    @keyframes showModal {
        0% {
            transform: translateX(1000px);
        }
        100% {
            display: flex;
            transform: translateX(0px);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .close-btn {
        border: none;
        background: none;
        color: $color_1;
    }
</style>