<template>
    <svg width="18" height="12" viewBox="0 0 18 12" :fill="`#${color}`" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0V12H5.5V7H9.5C9.77615 7 10 6.77615 10 6.5V0.5C10 0.223857 9.77615 0 9.5 0H5.5H4.75H4ZM10 8H7.5V8.5C7.5 8.77615 7.72386 9 8 9H13.5C13.6844 9 13.8539 8.8985 13.9409 8.73595C14.0279 8.57335 14.0183 8.3761 13.916 8.22265L12.101 5.5L13.916 2.77735C14.0183 2.62392 14.0279 2.42665 13.9409 2.26407C13.8539 2.10149 13.6844 2 13.5 2H11V7C11 7.55 10.55 8 10 8Z" :fill="`#${color}`" />
    </svg>
</template>

<script>
export default {
    props: {
        color: String
    }
}
</script>