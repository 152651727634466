<template>
    <div class="selection-process-icon">
        <div class="icon-box active" :class="{ 'active': currentStep === 1, 'passed' : currentStep > 1 }">
            <detailsIcon v-if="currentStep > 1" color="10b981"/>
            <detailsIcon v-if="currentStep === 1" color="ffffff"/>
            <detailsIcon v-else-if="currentStep < 1" color="6a6b6f"/>
            <p>Details</p>
        </div>
        <div class="icon-box" :class="{ 'active': currentStep === 2, 'passed' : currentStep > 2 }">
            <styleIcon v-if="currentStep > 2" color="10b981"/>
            <styleIcon v-if="currentStep === 2" color="ffffff"/>
            <styleIcon v-else-if="currentStep < 2" color="6a6b6f"/>
            <p>Style</p>
        </div>
        <div class="icon-box" :class="{ 'active': currentStep === 3, 'passed' : currentStep > 3 }">
            <goalIcon v-if="currentStep > 3" color="10b981"/>
            <goalIcon v-if="currentStep === 3" color="ffffff"/>
            <goalIcon v-else-if="currentStep < 3" color="6a6b6f"/>
            <p>Goals</p>
        </div>
        <div class="icon-box" :class="{ 'active': currentStep === 4, 'passed' : currentStep > 4 }">
            <profileIcon v-if="currentStep > 4" color="10b981"/>
            <profileIcon v-if="currentStep === 4" color="ffffff"/>
            <profileIcon v-else-if="currentStep < 4" color="6a6b6f"/>
            <p>Profile</p>
        </div>
    </div>
</template>
<script>
import detailsIcon from "../icons/detailsIcon.vue"
import styleIcon from "../icons/styleIcon.vue"
import profileIcon from "../icons/profileIcon.vue"
import goalIcon from "../icons/goalsIcon.vue"

export default {
    components: {detailsIcon, styleIcon, profileIcon, goalIcon},
    props: {
        currentStep: Number
    }
}
</script>
<style lang="scss">
    @import "../../assets/scss/common.scss";

    .icon-box.active {
        p {
            color: #ffffff !important;
        }
    }

    .icon-box.passed {
        p {
            color: $color_4 !important;
        }
    }

    .icon-box {
        p {
            color: $color_3;
        }
    }
</style>