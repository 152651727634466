<template>
    <div class="row closet-grid select-style-attributes-page">
      <div class="col-sm-12">
        <div class="d-none d-sm-block">
            <div class="col-lg-5 col-md-6">
                <span class="text-white">Desktop/tablet view is not developed yet.</span>
            </div>
        </div>
        <div class="d-block d-sm-none">
            <div class="page-image">
                <img class="img-fluid"
                    src="../assets/image/pages-image/how-closet-work.webp" 
                />
            </div>
            <div class="page-content">
                <h2 class="page-title">{{pageTitle}}</h2>
                <p class="page-description">{{pageDescription}}</p>

                <div class="form-group position-relative">
                    <input type="file" class="page-fileupload form-control">
                    <p class="page-fileupload-text">
                        <img src="../assets/image/svg/Photo.svg" />
                        <span>Add<br/>closet image</span>
                    </p>
                </div>
                <div class="form-group position-relative">
                    <input type="text" class="form-control" placeholder="Closet Name" />
                </div>
                <div class="form-group position-relative">
                    <input type="text" class="form-control" placeholder="Closet Description" />
                </div>
            </div>
            <div class="page-action">
                <button class="button-white" @click="$emit('continueButtonAction', 'home-slide')">{{ctaButtonMobile}}</button>
            </div>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HowClosetsWork",
    components: {
      },
      data: () => {
          return {
            pageTitle: 'HOW CLOSETS WORK',
            pageDescription: 'There were two bad people… One was John Wayne and he’s dead brother, and the other’s right here. Nature Boy Ric Flair, the World’s Heavyweight title belongs to these people.',
            ctaButton: 'Create your first closet',
            ctaButtonMobile: 'Create your first closet'
          };
      },
    computed: {
    },
      methods: {
      handleContinueClick() {
        console.log(this.selectedStyles);
      }
      },
  };
  </script>
  
  <style lang="scss">
  @import "../assets/scss/pages/HowClosetsWork";
  </style>
  